<template lang="pug">

EmbedCe(:url='ceUrl')

</template>

<script>

  export default {
    components: {
      EmbedCe: () => import('@/components/EmbedCe.vue'),
    },
    props: {
      taskAttribute: Object,
    },
    computed: {
      ceUrl () {
        return `TaskAttribute/${this.taskAttribute.TaskAttributeID}/`
      }
    }
  }
</script>